// src/pages/NotFound.jsx
import React from 'react';

const NotFound = () => (
  <div className="container my-4">
    <h1>404 - Page Not Found</h1>
    <p>The page you're looking for doesn't exist.</p>
  </div>
);

export default NotFound;
