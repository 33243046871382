// src/pages/Product.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import CarouselComponent from '../components/Carousel';
import ColorSwatches from '../components/ColorSwatches';
import InventoryTable from '../components/InventoryTable';
import ProductProofImagesComponent from '../components/ProductProofImagesComponent';
import LoadingIndicator from '../../../components/LoadingIndicator';

const Product = () => {
  const { styleID } = useParams();
  const navigate = useNavigate();
  const [styleData, setStyleData] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showProofImages, setShowProofImages] = useState(false);
  const [proofImageUrls, setProofImageUrls] = useState([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // Function to get Proof image URLs (excluding styleImage)
  const getProofImageUrls = useCallback(() => {
    let imageUrls = [];

    if (selectedColor) {
      const colorProducts = products.filter(p => p.colorName === selectedColor);
      const imageFields = [
        'colorFrontImage',
        'colorSideImage',
        'colorBackImage',
        'colorDirectSideImage',
        'colorOnModelFrontImage',
        'colorOnModelSideImage',
        'colorOnModelBackImage',
      ];

      colorProducts.forEach(product => {
        imageFields.forEach(field => {
          const imageUrl = product[field];
          if (imageUrl) {
            const fullUrl = `https://www.ssactivewear.com/${imageUrl}`;
            imageUrls.push({ url: fullUrl });
          }
        });
      });
    }

    // Remove potential duplicates
    const uniqueImageUrls = imageUrls.filter((img, index, self) =>
      index === self.findIndex((t) => (
        t.url === img.url
      ))
    );

    return uniqueImageUrls;
  }, [selectedColor, products]);

  // Fetch style and product data on mount or when styleID changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const styleResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/styles/${styleID}`);
        setStyleData(styleResponse.data[0]);

        const productsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/products/?style=${styleID}`);
        setProducts(productsResponse.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
        alert('Failed to load product details. Please try again later.');
      }
    };

    fetchData();
  }, [styleID]);

  // Update document title when styleData changes
  useEffect(() => {
    if (styleData) {
      document.title = `${styleData.brandName} ${styleData.title} - ${styleData.styleName}`;
    }
  }, [styleData]);

  // Filter products based on selected color
  useEffect(() => {
    if (selectedColor) {
      const colorProducts = products.filter(p => p.colorName === selectedColor);
      setFilteredProducts(colorProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedColor, products]);

  // Handle color change from dropdown or swatches
  const handleColorChange = (color) => {
    setSelectedColor(color);
    // Image URLs will be updated in useEffect below
  };

  // Navigate back to Home page
  const handleBackClick = () => {
    navigate('/'); // Navigate back to Home page
  };

  // Keyboard shortcut handler for Control+Alt+P
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.altKey && e.key.toLowerCase() === 'p') {
        e.preventDefault();
        toggleProofImages();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [showProofImages]);

  // Function to toggle the visibility of ProductProofImagesComponent
  const toggleProofImages = () => {
    if (showProofImages) {
      // Hide and clear
      setShowProofImages(false);
      setProofImageUrls([]);
      setToastMessage('Product Proof Images Hidden (Ctrl+Alt+P)');
      setToastVisible(true);
    } else {
      // Show and load images
      const imageUrls = getProofImageUrls();
      setProofImageUrls(imageUrls);
      setShowProofImages(true);
      setToastMessage('Loading Product Proof Images (Ctrl+Alt+P)');
      setToastVisible(true);
    }
  };

  // useEffect to update proofImageUrls when selectedColor changes and showProofImages is true
  useEffect(() => {
    if (showProofImages) {
      const imageUrls = getProofImageUrls();
      setProofImageUrls(imageUrls);
    }
  }, [selectedColor, showProofImages, getProofImageUrls]);

  if (!styleData) {
    return <LoadingIndicator />;
  }

  const uniqueColors = [...new Map(products.map(item => [item.colorName, item])).values()];

  return (
    <Container className="my-4">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBackClick} className="mb-3">
        &larr; Back to All Styles
      </Button>
      
      <Row>
        <Col md={4}>
          <CarouselComponent styleData={styleData} products={filteredProducts} selectedColor={selectedColor} />
        </Col>

        <Col md={8}>
          <h2>{`${styleData.brandName} ${styleData.title} - ${styleData.styleName}`}</h2>
          <div dangerouslySetInnerHTML={{ __html: styleData.description }} />
        </Col>
      </Row>

      {uniqueColors.length > 0 && (
        <Row className="my-4">
          <Col>
            <Form.Group className="my-3" controlId="colorDropdown">
              <Form.Label><strong>Select Color:</strong></Form.Label>
              <Form.Select 
                value={selectedColor} 
                onChange={(e) => handleColorChange(e.target.value)} 
                style={{ maxWidth: '300px' }}
              >
                <option value="">-- Select a Color --</option>
                {uniqueColors.map(product => (
                  <option key={product.colorName} value={product.colorName}>
                    {product.colorName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <ColorSwatches 
              colors={uniqueColors} 
              selectedColor={selectedColor} 
              onColorSelect={handleColorChange} 
            />
          </Col>
        </Row>
      )}

      {/* Conditionally render ProductProofImagesComponent above InventoryTable */}
      {showProofImages && proofImageUrls.length > 0 && (
        <Row>
          <Col>
            <ProductProofImagesComponent imageUrls={proofImageUrls} />
          </Col>
        </Row>
      )}

      {selectedColor && (
        <Row>
          <Col>
            <InventoryTable products={filteredProducts} selectedColor={selectedColor} />
          </Col>
        </Row>
      )}

      {/* Toast for visual indicator */}
      <ToastContainer position="top-end" className="p-3">
        <Toast 
          onClose={() => setToastVisible(false)} 
          show={toastVisible} 
          delay={3000} 
          autohide 
          bg="light"
        >
          <Toast.Header style={{ backgroundColor: '#E57200', color: 'white' }}>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body style={{ backgroundColor: 'white', color: 'black' }}>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default Product;
