import React from 'react';
import { Table, Badge, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';

const InventoryTable = ({ products = [], selectedColor }) => {
  // Ensure products is an array and has a valid length
  if (!Array.isArray(products) || products.length === 0) {
    return null;
  }

  // Define the desired warehouse order
  const warehouseOrder = ['AB', 'BC', 'ON'];

  // Extract unique sizes and warehouses
  const sizes = Array.from(new Set(products.map(p => p.sizeName)));
  let warehouses = Array.from(new Set(products.flatMap(p => p.warehouses.map(w => w.warehouseAbbr)))).filter(w => w !== 'DS');

  // Sort warehouses based on the defined order
  warehouses.sort((a, b) => {
    return warehouseOrder.indexOf(a) - warehouseOrder.indexOf(b);
  });

  // Function to determine if a day is a business day
  const isBusinessDay = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Sunday = 0, Saturday = 6
  };

  // Function to add business days
  const addBusinessDays = (date, days) => {
    let result = new Date(date);
    let addedDays = 0;
    while (addedDays < days) {
      result.setDate(result.getDate() + 1);
      if (isBusinessDay(result)) {
        addedDays += 1;
      }
    }
    return result;
  };

  // Function to find the next Tuesday or Thursday
  const getNextTueOrThu = (date) => {
    const day = date.getDay();
    let daysToAdd = 0;
    if (day === 2 || day === 4) { // Tuesday or Thursday
      daysToAdd = 0;
    } else if (day < 2) { // Sunday or Monday
      daysToAdd = 2 - day;
    } else if (day < 4) { // Wednesday
      daysToAdd = 4 - day;
    } else { // Friday or Saturday
      daysToAdd = (9 - day); // Next Tuesday
    }
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + daysToAdd);
    return nextDate;
  };

  // Updated getExpectedArrivalDate function
  const getExpectedArrivalDate = (warehouse) => {
    let daysToAdd;

    switch (warehouse) {
      case 'AB':
        daysToAdd = 2;
        break;
      case 'BC':
        daysToAdd = 3;
        break;
      case 'ON':
        daysToAdd = 5;
        break;
      default:
        daysToAdd = 5;
    }

    // Get current date and time in MST
    const now = new Date();
    const mstOffset = -7; // MST is UTC-7
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    const mstTime = new Date(utc + (mstOffset * 3600000));

    // Determine if today should be included
    let startDate = new Date(mstTime);
    if (mstTime.getHours() >= 12) {
      startDate.setDate(startDate.getDate() + 1);
    }

    // Find the next Tuesday or Thursday from startDate
    const nextTueOrThu = getNextTueOrThu(startDate);

    // Add daysToAdd business days from nextTueOrThu
    const arrivalDate = addBusinessDays(nextTueOrThu, daysToAdd);

    const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' };
    return arrivalDate.toLocaleDateString('en-US', options);
  };

  // Function to get price by size
  const getPriceBySize = (sizeName) => {
    const product = products.find(p => p.sizeName === sizeName);
    return product ? product.wescanPrice : 0;
  };

  // Get the color swatch image and text for the selected color
  const colorProduct = products.find(p => p.colorName === selectedColor) || {};
  const colorSwatchImage = colorProduct.colorSwatchImage
    ? `https://www.ssactivewear.com/${colorProduct.colorSwatchImage}`
    : null;
  const colorName = selectedColor || 'No Color Selected';
  const colorSwatchTextColor = colorProduct.colorSwatchTextColor || '#000';

  return (
    <div className="table-responsive" style={{ display: 'block' }}>
      <Table bordered hover striped className="text-center align-middle" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.05)', borderRadius: '8px', overflow: 'hidden' }}>
        <thead className="table-light">
          <tr>
            <th rowSpan="3" className="text-start align-middle" style={{ width: '200px', backgroundColor: '#f8f9fa' }}>
              <div 
                style={{ 
                  position: 'relative', 
                  width: '100px', 
                  height: '50px', 
                  marginBottom: '10px', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  background: colorProduct.color1
                    ? `linear-gradient(to right, ${colorProduct.color1 || '#ffffff'} 50%, ${colorProduct.color2 || colorProduct.color1 || '#ffffff'} 50%)`
                    : '#ffffff'
                }} 
              >
                <span 
                  style={{
                    position: 'absolute',
                    color: colorSwatchTextColor,
                    fontSize: '12px',
                    textAlign: 'center',
                    width: '100%',
                    padding: '2px'
                  }}
                >
                  {colorName}
                </span>
              </div>
              <strong>Warehouses</strong>
            </th>
            {sizes.map(size => (
              <th key={size} className="text-center" style={{ backgroundColor: '#f1f3f5' }}>{size}</th>
            ))}
          </tr>
          <tr>
            {sizes.map(size => (
              <th key={size} className="text-center" style={{ backgroundColor: '#f1f3f5' }}>${getPriceBySize(size).toFixed(2)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {warehouses.map(warehouse => (
            <tr key={warehouse}>
              <td className="text-start">
                <strong>{warehouse}</strong><br />
                <small>Arrives on {getExpectedArrivalDate(warehouse)}</small>
              </td>
              {sizes.map(size => {
                const product = products.find(p => p.sizeName === size && p.warehouses.some(w => w.warehouseAbbr === warehouse));
                const warehouseData = product ? product.warehouses.find(w => w.warehouseAbbr === warehouse) : null;
                const qty = warehouseData ? warehouseData.qty : 0;
                const expectedInventory = warehouseData ? warehouseData.expectedInventory : null;
                const isCloseout = warehouseData ? warehouseData.closeout : false;

                return (
                  <td key={size}>
                    <OverlayTrigger
                      placement="top"
                      trigger="click" // Tooltip shows on click
                      overlay={
                        <Tooltip id={`tooltip-${size}-${warehouse}`}>
                          {`Expected Inventory: ${expectedInventory || 'N/A'}`}
                        </Tooltip>
                      }
                      rootClose // Closes the tooltip when clicking outside
                    >
                      <span style={{ cursor: 'default', color: '#212529' }}>
                        {qty}
                      </span>
                    </OverlayTrigger>
                    {isCloseout && <Badge bg="danger" className="ms-2">DISCONTINUED</Badge>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default InventoryTable;
